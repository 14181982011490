<template>
	<div class="cbox">

		<el-dialog class="cus_dialog " title="编辑账号" :visible.sync="dialogShow0" :close-on-click-modal="false"
			width="90%" @close="closeDialog">
			<div class="headbar">
				<div style="float: right">


					<el-select v-model="searchForm.school_id" v-if="teacherInfo.pmid == 1" clearable placeholder="全部学校"
						size="small" style="width:300px;margin-left:10px">
						<el-option label="全部学校" value></el-option>
						<el-option label="-" :value="1"></el-option>
						<el-option :label="item.platform" v-for="(item, idx) in SchoolList" :key="idx"
							:value="item.id"></el-option>

					</el-select>



					<el-input placeholder="关键字" v-model="searchForm.keyword" size="small" class="input-with-select"
						style="width:150px;margin-left: 10px" clearable></el-input>


					<el-button icon="el-icon-search" @click="getDataList" style="margin-left:10px" size="small"
						type="primary">搜索</el-button>


					<el-button icon="el-icon-plus" @click="addNew" style="margin-left:10px" size="small"
						type="primary">添加账号</el-button>

					<el-button icon="el-icon-refresh" v-if="searchForm.school_id>1" @click="initMenuRole"
						style="margin-left:10px" size="small" type="primary">初始化角色菜单</el-button>









				</div>
				<div style="padding-top: 14px">

					<div style=" width: 200px;  margin-top:-10px;">
						<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
							<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
						</div>
						<div style="margin-left: 50px; color:#535353; font-size:20px !important">账号列表</div>
					</div>


				</div>
			</div>
			<div class="bcontent">
				<div>
					<el-table :data="tableData" row-key="id" border stripe>
						<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
						<el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column>
						<el-table-column label="登录账号" prop="username" min-width="150" align="center"></el-table-column>
						<el-table-column label="手机号" prop="phone" min-width="150" align="center"></el-table-column>
						<el-table-column label="可用时间段" prop="phone" min-width="150" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.login_starttime }}</span> 至
								<span>{{ scope.row.login_endtime }}</span>

							</template>

						</el-table-column>

						<el-table-column label="角色" prop="role" min-width="150" align="center">
							<template slot-scope="scope">


								<span style="margin-right: 5px;" v-for="(item, idx) in scope.row.sys_roles"
									:key="idx">{{
									item.role_name }}</span>

							</template>

						</el-table-column>

						<el-table-column label="账号类型" prop="role" min-width="150" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.isjyj">系统账号</span>
								<span v-else>子账号</span>

							</template>

						</el-table-column>

						<el-table-column label="排序号" prop="sort" width="90" align="center"></el-table-column>


						<el-table-column fixed="right" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="editRow(scope.row)" v-if="!scope.row.isjyj || $store.state.pmid == 1"
									type="text" size="small">编辑</el-button>

								<el-popconfirm v-if="!scope.row.isjyj || $store.state.pmid == 1" width="250"
									confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
									@confirm="resetPwd(scope.row)" placement="left" title="重置密码为手机号后6位（若无手机号则重置密码为888888），是否确定重置？">
									<el-button slot="reference" type="text" size="small" style="margin-left:10px;">重置密码</el-button>

								</el-popconfirm>
								<el-button @click="deleteRow(scope.row)" v-if="!scope.row.isjyj" type="text"
									size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<div style="text-align:right;padding-top: 10px;">
						<el-pagination background @current-change="handlePageChange" :current-page="page.current_page"
							:page-size="page.per_page" :total="page.count"
							layout="total, prev, pager, next"></el-pagination>
					</div>


				</div>

			</div>
		</el-dialog>

		<el-dialog class="cus_dialog " title="编辑账号" :visible.sync="dialogShow" :close-on-click-modal="false"
			width="600px" height="30%">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">

				<el-row style="padding-top: 20px;">
					<el-col :span="20">
						<el-form-item label="所属学校">
							<el-select :disabled="teacherInfo.pmid != 1" v-model="EditItem.school_id" clearable
								placeholder="选择学校" style="width:346px;margin-left:0px">
								<el-option label="教育局平台" :value="1"></el-option>
								<el-option :label="item.platform" v-for="(item, idx) in SchoolList" :key="idx"
									:value="item.id"></el-option>

							</el-select>


						</el-form-item>

					</el-col>
					<el-col :span="20">
						<el-form-item label="账号名称">
							<el-input v-model="EditItem.username" placeholder="账号名称，登录用"></el-input>

						</el-form-item>

					</el-col>

					<el-col :span="20">
						<el-form-item label="手机号码">
							<el-input v-model="EditItem.phone" placeholder="手机号码"></el-input>
              <span>默认密码为手机号后六位，无手机号则为888888</span>

						</el-form-item>

					</el-col>




					<el-col :span="20">
						<el-form-item label="可用时间段">
							<el-date-picker v-model="loginTimeRange" type="daterange" value-format="yyyy-MM-dd"
								align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
								end-placeholder="结束日期" :picker-options="pickerOptions" style="width:100%">
							</el-date-picker>

						</el-form-item>

					</el-col>

					<el-col :span="20">
						<el-form-item label="排序号">
							<el-input v-model="EditItem.sort" placeholder="排序号,号越小越靠上"></el-input>

						</el-form-item>

					</el-col>

					<el-col :span="20">
						<el-form-item label="角色类型">

							<el-checkbox-group v-model="teacherAuth">
								<el-checkbox :label="item.id" v-for="(item, idx) in roles" :key="idx">
									{{ item.role_name }}</el-checkbox>

							</el-checkbox-group>



							<!-- <el-select v-model="EditItem.role" clearable placeholder="选择角色"
								style="width:346px;margin-left:0px">
								<el-option label="管理员" value="admin"></el-option>
								<el-option label="校长" value="xz"></el-option>
								<el-option label="系主任" value="xzr"></el-option>
								<el-option label="班主任" value="bzr"></el-option>


							</el-select> -->
							<!-- 
							<div style="font-size:12px;color:orangered" v-if="EditItem.role == 'admin'">管理员账号可以创建子账号</div> -->



						</el-form-item>



					</el-col>
				</el-row>



			</el-form>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button type="primary" @click="saveForm">保存</el-button>
				</el-col>
			</el-row>

		</el-dialog>

	</div>
</template>

<script>

export default {
	props: ["tpmid"],
	components: {



	},
	data() {
		return {
			dialogShow0: true,
			dialogShow: false,
			dialogshow1: false,
			showTj: true,
			SchoolList: [],
			searchForm: {
				school_id: "",
				class_name: "",
				keyword: "",
				status: "",
				bxcompany: ""

			},
			TargetPmid: 0,
			tableData: [],
			TreeData: [],
			teacherInfo: {},
			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
				total_page: 0
			},
			EditItem: {},
			loginTimeRange: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			roles: [],
			teacherAuth: []
		}
	},
	mounted() {
		this.searchForm.school_id = this.tpmid ? this.tpmid : 0
		this.getSchoolList()
		this.getDataList()
		this.getTeacher()



	},
	methods: {
		closeDialog() {
			this.$$parent(this, "tpmid", 0)
		},
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				this.SchoolList = res.data
			})
		},
		getTeacher() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.teacherInfo = res.data
			})
		},

		addNew() {
			this.EditItem = { school_id: this.searchForm.school_id, username: "", phone: "", role: "", sort: "" }
			console.log(this.EditItem)
			this.dialogShow = true
			this.getRole()
		},
		initMenuRole(e) {
			this.$confirm('此操作将删除已有的管理员角色并重新创建，如已经初始化，请勿点击此操作?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				this.$http.post("/api/init_sch_menurole",{target_pmid:this.searchForm.school_id}).then(res => {
					this.$message({
					type: 'success',
					message: '初始化成功!'
				});
			})
				
			}).catch(() => {

			});
		},
		getDataList() {
			let data = {
				page: this.page.current_page,
				keyword: this.searchForm.keyword,
				ktype: "username",
				school_id: this.searchForm.school_id,
				showloading: true
			}
			this.$http.post("/api/t_sch_teachers_list", data).then(res => {
				for (let item of res.data.data) {
					if (item.login_starttime) {
						item.login_starttime = item.login_starttime.split(' ')[0]
						item.login_endtime = item.login_endtime.split(' ')[0]
					}
				}
				this.tableData = res.data.data
				this.page = res.data.page
				this.tongji = res.data.tongji
			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		editRow(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name

			this.EditItem = e
			if (e.login_starttime) {
				this.loginTimeRange = [e.login_starttime, e.login_endtime]
			}

			this.EditItem.school_id = e.pmid
			this.teacherAuth = [];
			this.teacherAuth = e.sys_roles.map(e => { return e.role_id })

			this.getRole()
			this.dialogShow = true
		},
		saveForm(e) {

			if (this.EditItem.phone) {
				if (/^1\d{10}$/.test(this.EditItem.phone)) {

				} else {
					this.$message.error("请输入正确的手机号")
					return
				}

			}
			if (!this.EditItem.school_id) {
				this.$message.error("请选择归属学校")
				return
			}
      if (!this.EditItem.username) {
        return this.$message.error('请输入账号名称')
      }
			if (this.loginTimeRange.length > 0) {
				this.EditItem.login_starttime = this.loginTimeRange[0]
				this.EditItem.login_endtime = this.loginTimeRange[1]
			}
			if(!this.EditItem.sort){
				this.EditItem.sort = 0
			}
			this.$http.post("/api/t_sch_teachers_edit", this.EditItem).then(res => {
				this.$message.success("保存成功!")
				this.dialogShow = false
				if (res.data.id) {
					this.EditItem.id = res.data.id
				}
				this.$http.post("/api/set_user_role", {
					teacher_id: this.EditItem.id,
          tpmid: this.EditItem.school_id,
					roleid: this.teacherAuth.join(",")
				}).then(res => {
					this.getDataList()
				})


			})



		},
		getRole() {
			this.$http.post("/api/sys_role_list", { pagesize: 100, school_id: this.EditItem.school_id }).then(res => {
				this.roles = res.data.data
			})
		},
		deleteRow(e) {

			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/t_sch_teachers_delete", { id: e.id }).then(res => {
					this.getDataList()
					this.$message({
						type: 'success',
						message: '删除成功!'
					});

				})
			}).catch(() => {

			});




		},

		resetPwd(e) {
			this.$http.post("/api/reset_teacher_pwd", {
				id: e.id
			}).then(res => {
				this.$message.success("已重置密码！")
			})

		},

	}
}
</script>
<style scoped></style>
