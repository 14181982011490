<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <el-tag style="margin-right:10px;cursor: pointer;" @click="dialogVisible2=true">可登录时间段：{{loginTimeRange[0]}}至{{loginTimeRange[1]}} <i
            class="el-icon-setting"></i></el-tag>
        <el-button size="small" icon="el-icon-plus" @click="add" type="primary">新增</el-button>
      </div>
      <div style="padding-top: 14px;">

        <div style=" width: 200px;  margin-top:-10px;">
          <div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
            <img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
          </div>
          <div style="margin-left: 50px; color:#535353; font-size:20px !important">学校管理</div>
        </div>



      </div>

    </div>
    <div class="bcontent">


      <el-table :data="DataList" stripe border>
        <el-table-column type="index" label="序号" width="80">
        </el-table-column>

        <el-table-column prop="id" label="学校编号" width="120">

        </el-table-column>

        <el-table-column prop="platform" label="学校名称" min-width="200">
        </el-table-column>
        <el-table-column prop="qsx_pmid" label="同步代码" min-width="200">
        </el-table-column>
        <el-table-column prop="sort" label="排序号" width="120">

        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)">删除</el-button>

            <el-button type="text" @click="showAccount(scope.row.id)">账号管理</el-button>
          
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="编辑学校" class="cus_dialog" :visible.sync="dialogVisible" :close-on-click-modal="false">


      <el-form :model="EditItem" ref="EditItem" label-width="160px">

        <el-row style="padding-top: 20px;">
          <el-col :span="20">
            <el-form-item label="学校名称">
              <el-input v-model="EditItem.platform" placeholder="请输入完整学校名称"></el-input>

            </el-form-item>

          </el-col>
          <el-col :span="20">
            <el-form-item label="同步代码">
              <el-input v-model="EditItem.qsx_pmid" placeholder="同步代码"></el-input>
              <div style="line-height: 16px; font-size: 12px; color: #046A81;">
                java版请输入学校拼音或缩写，saas版请输入pmid
              </div>
            </el-form-item>

          </el-col>
          <el-col :span="20">
            <el-form-item label="可修改数据时间段：">
              <el-date-picker v-model="EditItem.modifyDateRange" type="daterange" value-format="yyyy-MM-dd" align="right" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="padding-top: 0px;">
          <el-col :span="10">
            <el-form-item label="排序号">
              <el-input v-model="EditItem.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button type="primary" @click="saveForm">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>



    <el-dialog title="设置时间" class="cus_dialog" :visible.sync="dialogVisible2" :close-on-click-modal="false">
      <div style="height: 60px;"></div>
      <el-form :model="formData" label-width="160px">
        <el-form-item label="可登录时间段：">
          <el-date-picker v-model="loginTimeRange" type="daterange" value-format="yyyy-MM-dd" align="right" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <el-tooltip class="item" effect="dark" content="覆盖后所有账号都设置为当前时间段可登录，覆盖后可单独给账号设置登录时间段" placement="top" style="margin-left: 10px;">
            <el-checkbox v-model="fugaiAll">覆盖所有账号</el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="可修改数据时间段：">
          <el-date-picker v-model="modifyDateRange" type="daterange" value-format="yyyy-MM-dd" align="right" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <el-tooltip class="item" effect="dark" content="设置后所有学校在当前时间段内可修改数据，也可可单独给学校设置" placement="top" style="margin-left: 10px;">
            <el-checkbox v-model="setAll">设置所有学校</el-checkbox>
          </el-tooltip>
        </el-form-item>
      </el-form>
<!--      <el-row :gutter="20">
        <el-col :span="18" >
          <div class="flexBetween">
            <div>
              <span>可登录时间段：</span>
              <el-date-picker v-model="loginTimeRange" type="daterange" value-format="yyyy-MM-dd" align="right" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <el-tooltip class="item" effect="dark" content="覆盖后所有账号都设置为当前时间段可登录，覆盖后可单独给账号设置登录时间段" placement="top">
              <el-checkbox v-model="fugaiAll">覆盖所有账号</el-checkbox>
            </el-tooltip>
            <el-button type="primary" @click="saveTimeRange(1)">保存</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 30px;">
        <el-col :span="16" >
          <div class="flexBetween">
            <div>
              <span>可修改数据时间段：</span>
              <el-date-picker v-model="modifyDateRange" type="daterange" value-format="yyyy-MM-dd" align="right" range-separator="至"
                              start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
&lt;!&ndash;            <el-tooltip class="item" effect="dark" content="设置后所有学校在当前时间段可修改数据，也可单独给学校设置" placement="top">
              <el-checkbox v-model="setModifyAll">设置全部学校</el-checkbox>
            </el-tooltip>&ndash;&gt;
            <el-button type="primary" @click="saveTimeRange(2)">保存</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20" style="margin-top: 30px;">
        <el-col :span="12" >
          <div class="flexBetween">
            <div>
              <span>系统关闭时间：</span>
              <el-date-picker
                v-model="sysCloseTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </div>
&lt;!&ndash;            <el-tooltip class="item" effect="dark" content="设置后所有学校在当前时间可修改部分数据，也可单独给学校设置" placement="top">
              <el-checkbox v-model="setAll">设置全部学校</el-checkbox>
            </el-tooltip>&ndash;&gt;
            <el-button type="primary" @click="saveTimeRange(3)">保存</el-button>
          </div>
        </el-col>
      </el-row>-->


      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="saveTimeRange">保 存</el-button>
      </div>
    </el-dialog>

    <teacher :tpmid="tpmid" v-if="tpmid"></teacher>

  </div>
</template>

<script>
import teacher from "./teacher.vue"
export default {
  components:{
    teacher
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      EditItem: {
        platform: "",
        qsx_pmid: "",
        sort: 0,
        modifyDateRange: []
      },
      tpmid:0,
      DataList: [],
      loginTimeRange:[],
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        fugaiAll:false,
        setAll: false,
        modifyDateRange: [],
        sysCloseTime: "",
        formData: {},

    }
  },
  mounted() {
    this.getList();
    this.getTimeRange()
  },
  methods: {
    getList() {
      this.$http.post("/api/sys_platform_list", {
        page: 1,
        pagesize: 100
      }).then(res => {
        this.DataList = res.data.data
      })
    },
    update(item) {
      item = Object.assign({}, item, {modifyDateRange: []})
      if (item.start_date) {
        item.modifyDateRange = [item.start_date,item.end_date]
      }
      this.EditItem = item;
      this.dialogVisible = true
    },
    uploadCover(e) {

      this.EditItem.image = e.src
    },
    add() {
      this.EditItem = {
        platform: "",
        qsx_pmid: "",
        sort: 0,
        modifyDateRange: [],
      }
      this.dialogVisible = true
    },
    remove(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/sys_platform_delete", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getList();
        })

      }).catch(() => {

      });
    },
    saveForm() {
      if (this.EditItem.modifyDateRange.length == 2) {
        this.EditItem.start_date = this.EditItem.modifyDateRange[0]
        this.EditItem.end_date = this.EditItem.modifyDateRange[1]
      }
      this.$http.post("/api/sys_platform_edit", this.EditItem).then(res => {
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        this.dialogVisible = false
        this.getList();
      })
    },
    getTimeRange(){
      this.$http.post("/api/sys_logintime_get").then(res => {
        this.loginTimeRange = [res.data.time_start,res.data.time_end]
        this.modifyDateRange = [res.data.modify_start_date, res.data.modify_end_date]

      })
    },
    saveTimeRange() {
      let param = {}
      if(this.loginTimeRange.length==2) {
        param = {starttime:this.loginTimeRange[0],endtime:this.loginTimeRange[1],all:this.fugaiAll}
      }
      if (this.modifyDateRange.length == 2) {
        param.start_date = this.modifyDateRange[0]
        param.end_date = this.modifyDateRange[1]
        param.set_all = this.setAll
      }
      if (param) {
        this.$http.post('/api/set_sch_time_range', param).then(res => {
          this.$message({
            type: 'success',
            message: '设置成功'
          });
          this.dialogVisible2 = false
          this.getList()
        })
      }
    },
    showAccount(e){
      this.tpmid = e

    },
    
  }
}
</script>

<style scoped></style>
